<template>
  <v-card class="my-2" @click="goTo">
    <v-row no-gutters>

      <!-- Label -->
      <v-col cols="12">
        <v-row no-gutters justify="space-between">
          <div class="font-weight-bold subtitle-1 mx-1 primary-text-color" v-if="['HOME', 'WORK'].includes(data.type)">{{$t(`view.favorites.${data.type}`)}}</div>
          <div class="font-weight-bold subtitle-1 mx-1 primary-text-color" v-else>{{data.label | truncate}}</div>

          <v-menu
            :nudge-width="200"
            offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                width="48"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-card rounded>
              <v-list>
                <v-list-item @click="itineraryFrom">{{$t('view.favorites.buttons.itineraryFrom')}}</v-list-item>
                <v-list-item @click="itineraryTo">{{$t('view.favorites.buttons.itineraryTo')}}</v-list-item>
                <v-list-item @click="deleteFavorite(data._id)">{{$t('view.favorites.buttons.delete')}}</v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-row>
      </v-col>

      <v-col cols="12">
        <p class="ma-1">{{data.address}}</p>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MyFavoriteCard",

  props: {
    data: {type: Object, default: {}}
  },

  methods: {
    deleteFavorite(id) {
      this.$http
        .delete(`/map/users/${this.$session.get('id')}/favorites/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.$store.commit("alert/showSuccess", this.$t('view.favorites.success-deleted'));
          this.$emit("delete");
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
    },

    goTo() {
      if (this.data && this.data.coordinates.lat) {
        this.$router.replace({
          name: 'Home',
          query: {coords: `${this.data.coordinates.lat},${this.data.coordinates.lng}`}
        })
      } else
        this.$router.replace({name: 'Home', query: {q: this.data.label}});
    },

    itineraryFrom() {
      if (this.$route.name === 'Itinerary')
        this.$router.push({query:  {from: `${this.data.coordinates.lat},${this.data.coordinates.lng}`}});
      else
        this.$router.replace({name: "Itinerary", query:  {from: `${this.data.coordinates.lat},${this.data.coordinates.lng}`}});
    },

    itineraryTo() {
      if (this.$route.name === 'Itinerary')
        this.$router.push({query: {to: `${this.data.coordinates.lat},${this.data.coordinates.lng}`}});
      else
        this.$router.replace({name: "Itinerary", query:  {to: `${this.data.coordinates.lat},${this.data.coordinates.lng}`}});
    },
  }
}
</script>

<style scoped>

</style>